import React from 'react';
import { GenericItem } from 'lib/types';

/**
 * Generic list group properties
 */
export interface ListProps<T extends GenericItem> {
    /**
     * Id of Library
     *
     * @var {Array<T>}
     */
    items: T[];

    /**
     * Render ListItem
     *
     * @param {T} item Item to render.
     *
     * @return {React.ReactElement}
     */
    renderItem: (item: T) => React.ReactElement;

    /**
     * Renders a flush list if true.
     *
     * @var {boolean}
     */
    isFlush: boolean;
}

/**
 * Renders a generic list group
 *
 * @param {Array<T>} items List of items to render.
 * @param {CallableFunction} renderItem Renders element a when called.
 * @param {boolean} isFlush Renders a flush list if true.
 *
 * @return {React.ReactElement}
 */
export default function ListGroup<T extends GenericItem>({
    items,
    renderItem,
    isFlush = false
}: ListProps<T>): React.ReactElement {
    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <div className={'list-group mb-3 ' + (isFlush ? 'list-group-flush' : '')}>
            {items.map((item, index) => {
                return (
                    <div key={index} className='list-group-item list-group-item-action'>
                        {renderItem(item)}
                    </div>
                );
            })}
        </div>
    );
}
