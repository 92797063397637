import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Demo from '../components/Demo';
import SplitList from '../components/SplitListGroup';
import { Link } from 'lib/components';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link as rLink } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { graphql, StaticQueryDocument } from 'gatsby';
import Layout from '../components/layout';

type ManualProps = {
    id: number;
    name: string;
    slug: string;
};

type LibraryProps = {
    description: string;
    name: string;
    slug: string;
    manuals: ManualProps[];
};

type DetailsProps = {
    details: LibraryProps;
};

type DataProps = {
    data: { policylibrary: DetailsProps };
};

const query: StaticQueryDocument = graphql`
    query LibraryPage($slug: String!) {
        policylibrary {
            details: getPolicyLibrary(id: $slug, sk: "details#live") {
                description
                name
                manuals {
                    id
                    name
                    slug
                    sortOrder
                }
            }
        }
        locales: allLocale(filter: { ns: { in: ["general", "policy-library"] } }) {
            edges {
                node {
                    language
                    data
                    ns
                }
            }
        }
    }
`;

/**
 * Renders details about a library.
 *
 * @param {data} DataProps Data return from the graphql query
 *
 * @return {React.ReactElement}
 */
function LibraryDetails({ data }: DataProps): React.ReactElement {
    const { t } = useTranslation();

    // just makes sure that the manuals are sorted by there name
    data.policylibrary.details.manuals.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    /**
     * Renders a manual link.
     *
     * @param {Manual} item Associated manual from library.
     *
     * @return {React.ReactElement}
     */
    function renderManual(item: ManualProps): React.ReactElement {
        return (
            <Link to={`/manual/${item.slug}`} icon={faAngleRight} routerLink={rLink}>
                {item.name}
            </Link>
        );
    }

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Layout>
            <Helmet>
                <title>
                    {t('policy_library')} - {data.policylibrary.details.name}
                </title>
                <meta
                    property='og:title'
                    content={`${t('general:policy_library')} - ${data.policylibrary.details.name}`}
                />
                {/* <meta
                    property='og:url'
                    content={`https://policylibrary.mcnhealthcare.com/library/${data.policyLibrary.slug}`}
                /> */}
            </Helmet>

            <div>
                <div>
                    <div className='d-flex justify-content-center'>
                        <h4>{data.policylibrary.details.name}</h4>
                    </div>
                    <hr />
                    <div
                        className='justify-content-center text-center'
                        dangerouslySetInnerHTML={{ __html: data.policylibrary.details.description }}
                    />
                    <SplitList isFlush={false} items={data.policylibrary.details.manuals} renderItem={renderManual} />
                </div>
            </div>
        </Layout>
    );
}

export { LibraryDetails as default, query };
