import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { GenericItem } from 'lib/types';
import ListGroup, { ListProps } from './ListGroup';

/**
 * Renders a list into two different lists horizontally.
 *
 * @param {Array<T>} items Full list of items.
 * @param {function} renderItem Function used to render a list item.
 * @param {boolean} isFlush Renders the list without borders on the side.
 *
 * @return {React.ReactElement}
 */
export default function SplitListGroup<T extends GenericItem>({
    items,
    renderItem,
    isFlush = false
}: ListProps<T>): React.ReactElement {
    /**
     * Total length of the items.
     *
     * @var {number}
     */
    const length: number = items ? items.length : 0;

    /**
     * Midpoint of the items.
     *
     * @var {number}
     */
    const midpoint: number = Math.ceil(length / 2);

    /**
     * These items will be displayed on the left side of the list.
     *
     * @var {Array<T>}
     */
    const leftSide: T[] = items ? items.slice(0, midpoint) : [];

    /**
     * These items will be displayed on the right side of the list.
     *
     * @var {Array<T>}
     */
    const rightSide: T[] = items ? items.slice(midpoint, length) : [];

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Row>
            <Col sm={6}>
                <ListGroup isFlush={isFlush} items={leftSide} renderItem={renderItem} />
            </Col>
            <Col sm={6}>
                <ListGroup isFlush={isFlush} items={rightSide} renderItem={renderItem} />
            </Col>
        </Row>
    );
}
